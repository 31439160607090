@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=News+Cycle:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chonburi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chonburi&family=Saira+Stencil+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #a3a3a3;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #868383;
}
/* Hide number input increment/decrement buttons */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}

#pointer {
  position: relative;
  background: #0480b9;
}

#pointer:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 15px solid white;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

#pointer:before {
  content: "";
  position: absolute;
  right: -15px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 15px solid #0480b9;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.font-sangrila{
  font-family: "font-sangrila";
}
.abril-fatface-regular {
  font-family: "Abril Fatface", serif;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "certificate-bold";
  src: url("../font/ten.ttf");
}

@font-face {
  font-family: "RubikDirt-Regular";
  src: url("../font/RubikDirt-Regular.ttf");
}

.certificate-font{
  font-family: "certificate-bold";
  font-weight: 500;
}
.news-cycle-regular {
  font-family: "News Cycle", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "font-sangrila";
  src: url("../font/Kalimati Regular.otf");
}

#borderimg2 {
  border: 10px solid transparent; /* Transparent border to allow border-image */
  border-image: url(../img/icon/flower.png) 30% repeat; /* 30% slicing for the image */
  border-image-repeat: repeat; /* Ensure the image repeats in the border */
  width: 100%; /* Set a specific width for the element */
  height: 100%; /* Set a specific height for the element */
  box-sizing: border-box; /* Include padding and border in total element size */
}
.font-chonburi-regular {
  font-family: "Chonburi", serif;
}

.saira-stencil-one-regular {
  font-family: "Saira Stencil One", sans-serif;
}
.roboto-condensed {
  font-family: "Roboto Condensed", sans-serif;
}
.tox .tox-promotion{
  display: none!important;
}